import * as yup from "yup";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";

import {
    Backdrop,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    InputAdornment,
    InputLabel,
} from "@mui/material";

import image_mail from "../assets/site/MailIcon.svg";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { getUserKey, postData } from "../common/utils";
import { config } from "../env/config";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

export default function ForgotPassword({
    showForgotPasswordPopup,
    setShowForgotPasswordPopup,
}) {
    const userData = getUserKey();
    const navigate = useNavigate();
    const components = [
        FetchMail,
        VerifyOTP,
        ResetPassword,
        PasswordResetSuccess,
    ];
    const [currentStep, setCurrentStep] = useState(0);
    const [pwdResetSuccessMsg, setPwdResetSuccessMsg] = useState("");
    const StepComponent = components[currentStep];
    const [currentSchema, setCurrentSchema] = useState(yup.object());
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
    } = useForm({
        resolver: yupResolver(currentSchema),
    });
    const values = getValues();
    const [otpVerificationStatus, setOtpVerificationStatus] =
        useState("not-verified");

    useEffect(() => {
        setCurrentStep(0);
        setOtpVerificationStatus("not-verified");
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showForgotPasswordPopup]);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setPwdResetSuccessMsg("");
        setShowForgotPasswordPopup(false);
    };

    const progressForm = (event, reason) => {
        setCurrentStep(currentStep + 1);
    };

    const onSubmit = (values) => {
        if (currentStep === 0) {
            postEmail(values);
        } else if (currentStep === 1) {
            if (otpVerificationStatus !== "success") {
                postVerifyOtp(values);
            }
        } else if (currentStep === 2) {
            postNewPassword(values);
        }
    };

    const postEmail = async (values) => {
        try {
            const payload = { email: values.forgotPasswordMail };
            const res = await postData({
                url: `${config.serverUrl}/forgotVerifyEmail.php`,
                payload,
                highlightError: true,
            });
            if (res) {
                progressForm();
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }
    };

    const postVerifyOtp = async (values) => {
        try {
            const payload = {
                user_email: values.forgotPasswordMail,
                otp: values.otp,
            };
            const res = await postData({
                url: `${config.serverUrl}/forgotVerifyOtp.php`,
                payload,
                highlightError: true,
            });
            if (res === 1) {
                setOtpVerificationStatus("success");
                setTimeout(() => {
                    progressForm();
                }, 1500);
            } else {
                setOtpVerificationStatus("failed");
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }
    };

    const postNewPassword = async (values) => {
        try {
            const payload = {
                user_email: values.forgotPasswordMail,
                Password: values.password,
            };
            const res = await postData({
                url: `${config.serverUrl}/createNewPassword.php`,
                payload,
                getFullResponse: true,
                highlightError: true,
            });
            if (res.response) {
                setPwdResetSuccessMsg(res.msg);
                progressForm();
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }
    };
    const postResendOtp = async () => {
        try {
            const payload = {
                email: values.forgotPasswordMail,
                Password: values.password,
            };
            const res = await postData({
                url: `${config.serverUrl}/signupresendotp.php`,
                payload,
                getFullResponse: true,
                highlightError: true,
            });
            if (res.status === "success") {
                toast.success("OTP sent successfully!");
            }
        } catch (error) {
            toast.error("Something went wrong!");
        }
    };

    const hasError = (element) => !!errors[element];
    const getError = (element) => {
        if (errors[element]) {
            return Object.values(errors[element])[0];
        }
    };

    return (
        <div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showForgotPasswordPopup}
            >
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={showForgotPasswordPopup}
                    disableEscapeKeyDown
                    hideBackdrop
                    onClose={handleClose}
                >
                    <Box>
                        <Button
                            sx={{
                                margin: "5px",
                                color: "black",
                            }}
                            onClick={handleClose}
                            variant="text"
                        >
                            <ChevronLeftOutlinedIcon />
                            Back
                        </Button>
                    </Box>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{ width: { xs: "100%", md: 400 }, margin: "auto" }}
                    >
                        <StepComponent
                            handleClose={handleClose}
                            handleMailSubmit={onSubmit}
                            progressForm={progressForm}
                            setCurrentSchema={setCurrentSchema}
                            otpVerificationStatus={otpVerificationStatus}
                            hasError={hasError}
                            getError={getError}
                            register={register}
                            postEmail={postEmail}
                            values={values}
                            postResendOtp={postResendOtp}
                            pwdResetSuccessMsg={pwdResetSuccessMsg}
                        />
                    </Box>
                </Dialog>
            </Backdrop>
        </div>
    );
}

const FetchMail = ({
    hasError,
    getError,
    register,
    setCurrentSchema,
    userData,
}) => {
    const validationSchema = yup.object({
        forgotPasswordMail: yup
            .string()
            .email("Please enter a valid email id.")
            .required("Required"),
    });

    useEffect(() => {
        setCurrentSchema(validationSchema);
    }, []);

    return (
        <>
            <DialogTitle
                sx={{ textAlign: "center", color: "#4B285D", fontSize: "20px" }}
            >
                Forgot Password?
            </DialogTitle>
            <DialogContent>
                <InputLabel shrink htmlFor="forgotPasswordMail">
                    Enter Your Email ID
                </InputLabel>
                <TextField
                    fullWidth
                    size="small"
                    id="forgotPasswordMail"
                    name="forgotPasswordMail"
                    autoComplete="forgotPasswordMail"
                    {...register("forgotPasswordMail")}
                    error={hasError("forgotPasswordMail")}
                    helperText={getError("forgotPasswordMail")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    sx={{
                        background: "#F67800",
                        color: "white",
                        margin: "0 18px 20px",
                    }}
                    fullWidth
                    variant="contained"
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    );
};

const VerifyOTP = ({
    register,
    hasError,
    getError,
    postEmail,
    setCurrentSchema,
    otpVerificationStatus,
    postResendOtp,
    values,
}) => {
    const validationSchema = yup.object({
        // otp1: yup.string().required("Required"),
        // otp2: yup.string().required("Required"),
        // otp3: yup.string().required("Required"),
        // otp4: yup.string().required("Required"),
        otp: yup
            .number()
            .positive()
            .nullable()
            .typeError("Please enter a valid OTP")
            .required("OTP is required"),
    });

    useEffect(() => {
        setCurrentSchema(validationSchema);
    }, []);

    // const hasOtpError =
    //   hasError("otp1") ||
    //   hasError("otp2") ||
    //   hasError("otp3") ||
    //   hasError("otp4");
    const hasOtpError = hasError("otp");
    return (
        <Box textAlign="center">
            {otpVerificationStatus === "not-verified" && (
                <Box
                    component="img"
                    sx={{
                        width: "50px",
                        height: "50px",
                    }}
                    alt="Mail OTP"
                    src={image_mail}
                />
            )}
            {otpVerificationStatus === "success" && (
                <CheckCircleOutlineRoundedIcon
                    sx={{
                        color: "#00D060",
                        width: "50px",
                        height: "50px",
                    }}
                />
            )}
            {otpVerificationStatus === "failed" && (
                <CancelOutlinedIcon
                    sx={{
                        color: "#FF0000",
                        width: "50px",
                        height: "50px",
                    }}
                />
            )}
            <DialogTitle
                sx={{ textAlign: "center", color: "#4B285D", fontSize: "17px" }}
            >
                {otpVerificationStatus === "not-verified" &&
                    "Enter OTP Sent on your Registered Email ID"}
                {otpVerificationStatus === "success" &&
                    "Email OTP Verification has match Successfully"}
                {otpVerificationStatus === "failed" && "Oops! Invalid OTP"}
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        columnGap: "10px",
                        justifyContent: "center",
                    }}
                >
                    <Typography sx={{ color: "#4D285C", fontSize: "14px" }}>
                        {otpVerificationStatus !== "failed" &&
                            "Registered Email ID:"}
                        {otpVerificationStatus === "failed" &&
                            "Please check the OTP on the Registered Email ID:"}
                    </Typography>
                    <Typography
                        sx={{
                            color: "#4D285C",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                    >
                        {values?.forgotPasswordMail}
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        color: "#4D285C",
                        fontSize: "12px",
                        textAlign: "center",
                        marginTop: "32px",
                    }}
                >
                    Enter your OTP
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        columnGap: "20px",
                        justifyContent: "center",
                        margin: "10px 0 20px",
                    }}
                >
                    <TextField
                        sx={{ width: 200 }}
                        name="otp"
                        {...register("otp")}
                        error={hasError("otp")}
                        type="text"
                    />
                    {/* <TextField
            sx={{ width: 70 }}
            name="otp1"
            {...register("otp1")}
            error={hasError("otp1")}
            type="number"
            inputProps={{ maxLength: 1 }}
          />
          <TextField
            sx={{ width: 50 }}
            name="otp2"
            {...register("otp2")}
            error={hasError("otp2")}
            type="text"
          />
          <TextField
            sx={{ width: 50 }}
            name="otp3"
            {...register("otp3")}
            error={hasError("otp3")}
            type="text"
          />
          <TextField
            sx={{ width: 50 }}
            name="otp4"
            {...register("otp4")}
            error={hasError("otp4")}
            type="text"
          /> */}
                </Box>
                <div>
                    {hasOtpError && (
                        <FormHelperText error sx={{ textAlign: "center" }}>
                            {getError("otp")}
                        </FormHelperText>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    sx={{
                        background: "#F67800",
                        color: "white",
                        margin: "0 18px 20px",
                    }}
                    fullWidth
                    variant="contained"
                >
                    VERIFY OTP
                </Button>
            </DialogActions>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        color: "#4D285C",
                        fontSize: "14px",
                        textAlign: "center",
                    }}
                >
                    Getting problem Receiving OTP
                </Typography>
                <Button
                    sx={{
                        textTransform: "none",
                        fontSize: "14px",
                        color: "#0062FF",
                    }}
                    onClick={postResendOtp}
                >
                    Resend OTP?
                </Button>
            </Box>
            <Typography
                sx={{
                    color: "#4D285C",
                    fontSize: "11px",
                    textAlign: "center",
                    marginBottom: "32px",
                }}
            >
                Helpline No: +919604489537
            </Typography>
        </Box>
    );
};

const ResetPassword = ({ hasError, getError, register, setCurrentSchema }) => {
    const validationSchema = yup.object({
        password: yup
            .string()
            .required("Please enter your password.")
            .min(4, "Your password is too short.")
            .max(4, "Max 4 digits are allowed.")
            .matches(/^\d{4}$/, "Please enter numeric password"),
        confirmPassword: yup
            .string()
            .required("Please retype your password.")
            .oneOf([yup.ref("password")], "Your passwords do not match."),
    });

    useEffect(() => {
        setCurrentSchema(validationSchema);
    }, []);

    return (
        <>
            <DialogTitle
                sx={{ textAlign: "center", color: "#4B285D", fontSize: "20px" }}
            >
                Reset your Password
            </DialogTitle>
            <DialogContent>
                <Box>
                    <DialogContentText
                        sx={{
                            color: "#4D285C",
                            fontSize: "14px",
                        }}
                    >
                        Enter 4 Digit Password*
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        type="password"
                        {...register("password")}
                        error={hasError("password")}
                        helperText={getError("password")}
                        fullWidth
                    />
                </Box>
                <Box>
                    <DialogContentText
                        sx={{
                            color: "#4D285C",
                            fontSize: "14px",
                        }}
                    >
                        Confirm 4 Digit Password*
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="confirmPassword"
                        {...register("confirmPassword")}
                        error={hasError("confirmPassword")}
                        helperText={getError("confirmPassword")}
                        type="password"
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    sx={{
                        background: "#F67800",
                        color: "white",
                        width: { xs: "100%", md: "220px" },
                        margin: "0 18px 20px",
                    }}
                    fullWidth
                    variant="contained"
                >
                    Submit
                </Button>
            </DialogActions>
        </>
    );
};

const PasswordResetSuccess = ({ pwdResetSuccessMsg, handleClose }) => {
    return (
        <>
            <DialogTitle
                sx={{ textAlign: "center", color: "#4B285D", fontSize: "20px" }}
            >
                Password Reset Successful
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ color: "#4D285C", fontSize: "14px" }}>
                    {pwdResetSuccessMsg}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        background: "#F67800",
                        color: "white",
                        width: { xs: "100%", md: "220px" },
                        margin: "0 18px 20px",
                    }}
                    fullWidth
                    variant="contained"
                    onClick={handleClose}
                >
                    Ok
                </Button>
            </DialogActions>
        </>
    );
};
