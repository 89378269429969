import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import {
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useState } from "react";

export const SpecialDhPlans = ({
    show,
    onCloseHandler,
    onContinue,
    specialDhPlans,
}) => {
    if (specialDhPlans && !Array.isArray(specialDhPlans)) {
        onContinue({
            specialPlanId: specialDhPlans.specialDh_plan_id,
            specialPlanAmountId: specialDhPlans.id,
            specialPlanAmount: specialDhPlans.plan_amount,
        });
    }

    const [expanded, setExpanded] = useState("panel0");

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const closeHandler = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onCloseHandler();
    };

    return (
        <Dialog
            open={show}
            onClose={closeHandler}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle
                sx={{ color: "#3f51b5", textAlign: "center", fontSize: "22px" }}
            >
                Payment Plan
                {specialDhPlans.length > 1 ? (
                    <Typography sx={{ color: "#666" }}>
                        Click on arrow button to see more details
                    </Typography>
                ) : null}
            </DialogTitle>
            <DialogContent>
                {specialDhPlans.length === 1 ? (
                    <TableRenderer
                        onContinue={onContinue}
                        plan={specialDhPlans[0]}
                    />
                ) : (
                    <div>
                        {specialDhPlans.map((plan, index) => (
                            <Accordion
                                key={plan.id}
                                expanded={expanded === `panel${index}`}
                                onChange={handleAccordionChange(
                                    `panel${index}`
                                )}
                                sx={{
                                    border: "1px solid #eee",
                                    borderRadius: "4px",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    sx={{
                                        background: "#f8f9ff",
                                    }}
                                    expandIcon={<GridExpandMoreIcon />}
                                >
                                    <Typography
                                        sx={{
                                            marginTop: "10px",
                                        }}
                                    >
                                        {plan.plan_name}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableRenderer
                                        onContinue={onContinue}
                                        plan={plan}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "right" }}>
                <Button
                    size="medium"
                    variant="contained"
                    onClick={onCloseHandler}
                    sx={{
                        marginRight: "10px",
                        background: "#78909c",
                        marginBottom: "10px",
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const TableRenderer = ({ plan, onContinue }) => {
    return (
        <TableContainer
            component={Paper}
            sx={{
                border: "1px solid #eee",
                boxShadow: "none",
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight={"600"} fontSize="17px">
                                Sr No
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"600"} fontSize="17px">
                                Plan Name
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"600"} fontSize="17px">
                                Amount
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"600"} fontSize="17px">
                                Payment Status
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {plan?.amountDetails?.map((amount, index) => (
                        <TableRowRenderer
                            amount={amount}
                            rowNum={index + 1}
                            onContinue={onContinue}
                            plan={plan}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const TableRowRenderer = ({ amount, rowNum, onContinue, plan }) => {
    return (
        <TableRow>
            <TableCell>
                <Typography fontSize="17px">{rowNum}</Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize="17px">{amount.plan_name}</Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize="17px">{amount.plan_amount}</Typography>
            </TableCell>
            <TableCell>
                {amount.payStatus === "has-paid" ? (
                    <Typography fontSize="17px">Paid</Typography>
                ) : amount.payStatus === "pay-later" ? (
                    <Typography fontSize="17px">Future Payment</Typography>
                ) : (
                    <Button
                        size="medium"
                        variant="contained"
                        onClick={() =>
                            onContinue({
                                specialPlanId: plan.id,
                                specialPlanAmountId: amount.id,
                                specialPlanAmount: amount.plan_amount,
                            })
                        }
                        sx={{
                            marginRight: "10px",
                            marginBottom: "10px",
                        }}
                    >
                        Pay Now
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
};
